<template>
  <div class="cal">
    <main-header />
    <div class="logo-box text-center">
      <img class="logo" src="../assets/otam-logo-new.png" width="90%" />
    </div>
    <div class="box-columns">
      <div class="more-info-box">
        <h5>Bonus za ilość przejazdów Bolt 🚕</h5>
        W każdym miesiącu trzech najlepszych kierowców otrzyma premie za
        największą liczbę przejazdów na aplikacji Bolt.
        <div class="bonus">
          🥇 600 PLN netto
          <br />
          🥈 300 PLN netto
          <br />
          🥉 100 PLN netto
        </div>
      </div>
      <div class="more-info-box">
        <h5>Bonus za polecenie 🚦</h5>
        Jeżeli polecisz nas komuś jako Partnera Flotowego, a polecona osoba
        zatrudni się u nas i wykona co najmniej 250 kursów to w zmian otrzymasz
        <strong>300 PLN netto</strong>, a osoba która wykona te przejazdy
        dodatkowe <strong>200 PLN netto</strong>.
      </div>
    </div>

    <div class="calendar-container">
      <b-row class="seven-cols" v-if="isLoading">
        <b-col lg="1" v-for="n in 7" :key="n" class="skeleton-desktop"
          ><b-skeleton-img class="skeleton-img" height="250px" width="185px">
          </b-skeleton-img
        ></b-col>
        <b-col lg="1" v-for="n in 7" :key="n + 'mobile'" class="skeleton-mobile"
          ><b-skeleton-img height="300px" width="80%"> </b-skeleton-img
        ></b-col>
      </b-row>
      <b-row class="seven-cols" v-if="!isLoading && getCapacity">
        <b-col lg="1" v-for="item in generateNextMonth" :key="item.day">
          <single-day
            @dayChanged="updateSchedule"
            :day="item.day"
            :month="item.month"
            :year="item.year"
            :isWeekend="item.isWeekend"
            :weekDay="item.weekDay"
            :currentDay="item.currentDay"
            :currentNight="item.currentNight"
            :currentChoose="item.currentToChoose"
            :dayLimits="getShiftLimits"
            :defaultLimit="getSettings.defaultLimit"
            :anotherDays="monthShedule"
            :errorDays="checkNightShift.errorDays"
            :bonuses="item.bonuses"
          />
        </b-col>
      </b-row>
      <b-sidebar
        id="sidebar-right"
        title="Wybrane zmiany"
        backdrop
        right
        shadow="lg"
        @change="changeSidebarState"
      >
        <div
          class="sidebar-summary"
          v-if="shiftCounter.day + shiftCounter.night > 0"
        >
          <p>
            <strong class="summary-all summary-single"
              >Suma zmian:
              {{
                shiftCounter.day + shiftCounter.night + shiftCounter.idk
              }}</strong
            >
          </p>
        </div>
        <div class="sidebar-summary" v-if="shiftCounter.total === 0">
          Nie wybrałeś jeszcze żadnej zmiany.
        </div>
        <div class="info-row">
          <div class="sidebar-summary" v-if="shiftCounter.day > 0">
            <p>
              <strong class="day-summary summary-single"
                >Dzień: {{ shiftCounter.day }}</strong
              >
            </p>
          </div>
          <div class="sidebar-summary" v-if="shiftCounter.night > 0">
            <p>
              <strong class="night-summary summary-single"
                >Noc: {{ shiftCounter.night }}</strong
              >
            </p>
          </div>
          <div class="sidebar-summary" v-if="shiftCounter.idk > 0">
            <p>
              <strong class="idk-summary summary-single"
                >Dyspozycja: {{ shiftCounter.idk }}</strong
              >
            </p>
          </div>
        </div>
        <div
          class="sidebar-content"
          v-for="shift in showChoosen"
          :key="shift.day"
        >
          <p v-if="shift.shiftOption !== null">
            {{ shift.day }}.{{ shift.month }}.{{ shift.year }} -
            {{ shift.shiftOption }}
          </p>
        </div>
      </b-sidebar>
      <div class="bottom-box" v-if="!send">
        <div
          class="info"
          v-if="false && shiftInfo && !sidebar && shiftInfo.shiftMissing > 0"
        >
          Dobierz jeszcze {{ shiftInfo.shiftMissing }} {{ returnWord }} aby
          otrzymać bonus {{ shiftInfo.bonus }} PLN.
        </div>
        <div class="info" v-if="!sidebar && shiftCounter.total === 7">
          Gratulacje! Otrzymasz do wypłaty 50 zł.
        </div>
        <div v-b-modal.submit-box class="submit">Dalej</div>
      </div>
    </div>
    <b-modal
      id="submit-box"
      centered
      size="lg"
      title="Prześlij grafik"
      hide-header-close
      cancel-title="Powrót"
      ok-title="Prześlij"
      @ok="handleSubmit"
      :ok-disabled="nextWeekClosed"
    >
      <b-alert show v-if="checkNightShift.isError" variant="danger">
        <p>
          Niestety, nie możesz wybrać zmiany dziennej - jeżeli dzień wcześniej
          wybrałeś zmianę nocną.
        </p>
        Zamień następujące nocne zmiany na inne:
        <ul>
          <li v-for="item in checkNightShift.errorDays" :key="item.day">
            {{ item.day }}.{{ item.month }}.{{ item.year }}
          </li>
        </ul>
      </b-alert>
      <b-alert :show="nextWeekClosed" variant="danger"
        >Grafik na kolejny tydzień został już wygenerowany i nie możesz przesłać dyspozycyjności. Zaloguj się na swoje konto <a style="color: inherit; font-weight: 600" href="https://grafik.otam.com.pl/kalendarz-pracy">tutaj</a> i dobierz zmiany lub skontaktuj się z nami.</b-alert
      >
      <b-alert :show="checkReserve" variant="danger"
        >W dniu wyświetlanym na czarno nie ma już miejsc, wybierz inną zmianę lub ustaw dzień z czarnym tłem jako <strong>Wolne</strong>.</b-alert
      >
      <b-alert show variant="warning"
        >Pamiętaj aby wprowadzić <strong>poprawny numer</strong> telefonu. W
        przeciwnym wypadku nie będziemy w stanie poprawnie przypisać Twojego
        pojazdu.</b-alert
      >
      <b-form-group
        label="Imię i nazwisko"
        label-for="input-1"
        invalid-feedback="Wprowadź poprawne imię i nazwisko"
      >
        <b-form-input
          type="text"
          id="input-1"
          v-model="form.fullName"
          :state="validate.fullName"
          trim
          lazy
        />
      </b-form-group>
      <b-form-group
        label="Adres e-mail"
        label-for="input-2"
        invalid-feedback="Wprowadź poprawny adres e-mail"
      >
        <b-form-input
          type="email"
          id="input-2"
          v-model="form.email"
          :state="validate.email"
          trim
          lazy
        />
      </b-form-group>
      <b-form-group
        label="Numer telefonu"
        label-for="input-3"
        invalid-feedback="Wprowadź poprawny numer telefonu"
      >
        <b-input-group prepend="+48">
          <b-form-input
            type="number"
            id="input-3"
            v-model="form.phone"
            :state="validate.phone"
            trim
            lazy
          />
        </b-input-group>
      </b-form-group>
      <!-- <b-button v-if="isFirstDay" @click="sendEmpty" block variant="danger"
        >Prześlij pustą dyspozycyjność</b-button
      > -->
    </b-modal>
    <!-- <page-footer /> -->
  </div>
</template>

<script>
import SingleDay from "../components/SIngleDay.vue";
import MainHeader from "../components/MainHeader.vue";
import PageFooter from "../components/Footer.vue";
import AdminSidebar from "./AdminSidebar.vue";

export default {
  name: "Calendar",
  components: {
    SingleDay,
    MainHeader,
    PageFooter,
    AdminSidebar,
  },
  data() {
    return {
      monthShedule: [],
      isLoading: false,
      generatedWeek: null,
      nextWeekClosed: false,
      form: { email: null, fullName: null, phone: null },
      images: {
        dayOff: null,
        dayShift: null,
        nightShift: null,
        freeChoice: null,
      },
      firstDay: { freeDay: null, isMonday: null },
      sidebar: false,
      send: false,
    };
  },
  beforeMount() {
    for (let i = 0; i < 32; i++) {
      this.monthShedule[i] = {
        day: i,
        shiftOption: null,
        isWeekend: null,
        year: null,
        month: null,
        weekDay: null,
      };
    }
  },
  mounted() {
    this.getAvailability();
  },
  methods: {
    updateSchedule(singleDay) {
      this.monthShedule.splice(singleDay.day, 1, singleDay);
    },
    async setWeek(week) {
      this.$bvModal
        .msgBoxConfirm(
          `Nim przejdziesz do innego tygodnia prześlij swoją dyspozycyjność - w innym wypadku zostatnie ona wyczyszczona.`,
          {
            title: "Zmiana tygodnia",
            centered: true,
            okTitle: "Potwierdzam",
            okVariant: "danger",
            cancelTitle: "Anuluj",
          }
        )
        .then(async (value) => {
          if (value) {
            this.showChoosen.forEach((el) => {
              el.shiftOption = null;
            });
            this.returnNextWeek.week = week;
            await this.getAvailability();
          }
        })
        .catch((error) => console.log(error));
    },

    async fetchSettings() {
      this.isLoading = true;
      await this.$store.dispatch("fetchSettings");
    },
    async getAvailability() {
      await this.fetchSettings();
      await this.$store
        .dispatch("getAvailability", {
          year: this.returnNextWeek.year,
          week: this.returnNextWeek.week,
        })
        .catch((error) => console.log(error));
      const nextWeekStatus = await this.$store.dispatch("isNextWeekGenerated");
      this.nextWeekClosed = nextWeekStatus.data.isGenerated 
      await this.$store.dispatch("getPublicBonus", this.today);
      this.isLoading = false;
    },
    changeSidebarState() {
      this.sidebar = !this.sidebar;
    },
    async submitForm() {
      const selectedDays = this.monthShedule.filter(
        (item) => item.shiftOption && item.shiftOption !== "Wolne"
      );
      const lastSended = parseInt(localStorage.getItem("week"));
      if (
        this.validate.email &&
        this.validate.fullName &&
        this.validate.phone &&
        this.returnNextWeek.week !== lastSended && !this.checkReserve
      ) {
        await this.$store
          .dispatch("sendForm", {
            selectedDays: selectedDays,
            fullName: this.form.fullName,
            email: this.form.email,
            phone: this.form.phone,
            weekNum: this.returnNextWeek.week,
            empty: false,
          })
          .then((res) => {
            this.$bvModal.msgBoxOk(
              "Formularz został przesłany pomyślnie, na Twój adres e-mail przesłany został harmonogram.",
              {
                headerBgVariant: "primary",
                title: "Przesłano pomyślnie",
                headerTextVariant: "light",
                centered: true,
              }
            );
            localStorage.setItem("week", this.returnNextWeek.week);
          })
          .catch((err) => {
            this.$bvModal.msgBoxOk(
              "Skontaktuj się z biurem w sprawie rozwiązania problemu wysyłyki.",
              {
                headerBgVariant: "danger",
                title: "Wystąpił błąd",
                okVariant: "danger",
                headerTextVariant: "light",
                centered: true,
              }
            );
          });
      } else if (lastSended === this.returnNextWeek.week) {
        await this.$bvModal.msgBoxOk(
          "Wygląda na to, że przesyłałeś już grafik na ten tydzień. Skontaktuj się z biurem celem ewentualnych poprawek.",
          {
            headerBgVariant: "danger",
            title: "Wysyłałeś już grafik na ten tydzień.",
            okVariant: "danger",
            headerTextVariant: "light",
            centered: true,
          }
        );
      } else {
        await this.$bvModal.msgBoxOk(
          "Przed wysłaniem formularza upewnij się, że wprowadziłeś wszystkie dane.",
          {
            headerBgVariant: "danger",
            title: "Dane są niekompletne",
            okVariant: "danger",
            headerTextVariant: "light",
            centered: true,
          }
        );
        this.$root.$emit("bv::show::modal", "submit-box");
      }
    },
    async sendEmpty() {
      const lastSended = parseInt(localStorage.getItem("week"));
      if (
        this.validate.email &&
        this.validate.fullName &&
        this.validate.phone &&
        this.returnNextWeek.week !== lastSended
      ) {
        this.send = true;
        await this.$store
          .dispatch("sendForm", {
            selectedDays: [],
            fullName: this.form.fullName,
            email: this.form.email,
            phone: this.form.phone,
            weekNum: this.returnNextWeek.week,
            empty: true,
            year: this.returnNextWeek.year,
            month: this.returnNextWeek.month,
          })
          .then((res) => {
            this.$bvModal.msgBoxOk(
              "Pusty formularz został przesłany pomyślnie, na Twój adres e-mail przesłany zostało przesłane potwierdzenie.",
              {
                headerBgVariant: "primary",
                title: "Przesłano pomyślnie",
                headerTextVariant: "light",
                centered: true,
              }
            );
            localStorage.setItem("week", this.returnNextWeek.week);
          })
          .catch((err) => {
            console.log(err.msg);
            this.$bvModal.msgBoxOk(
              "Niestety, wystąpił nieoczekiwany błąd. Spróbuj ponownie później.",
              {
                headerBgVariant: "danger",
                title: "Wystąpił błąd",
                okVariant: "danger",
                headerTextVariant: "light",
                centered: true,
              }
            );
          });
      } else if (lastSended === this.returnNextWeek.week) {
        await this.$bvModal.msgBoxOk(
          "Wygląda na to, że przesyłałeś już grafik na ten tydzień. Skontaktuj się z biurem celem ewentualnych poprawek.",
          {
            headerBgVariant: "danger",
            title: "Wysyłałeś już grafik na ten tydzień.",
            okVariant: "danger",
            headerTextVariant: "light",
            centered: true,
          }
        );
      } else {
        await this.$bvModal.msgBoxOk(
          "Przed wysłaniem formularza upewnij się, że wprowadziłeś wszystkie dane.",
          {
            headerBgVariant: "danger",
            title: "Dane są niekompletne",
            okVariant: "danger",
            headerTextVariant: "light",
            centered: true,
          }
        );
        this.$root.$emit("bv::show::modal", "submit-box");
      }
    },
    async handleSubmit() {
      if (this.showChoosen.length) {
        await this.submitForm();
      } else {
        await this.sendEmpty();
      }
    },
  },
  computed: {
    generateNextMonth() {
      const lookForMonday = this.$dayjs().add(7, 'day')
        .weekday(0);

      const nextMonday = new Date(lookForMonday.$d);
      const month = nextMonday.getMonth() + 1;
      const year = nextMonday.getFullYear();
      const day = nextMonday.getDate();
      const capacity = this.getCapacity;
      let dayObject = [];
      for (let i = 0; i < 7; i++) {
        let bonuses = { day: { value: null }, night: { value: null } };
        let makeDate = new Date(year, month - 1, day + i);
        const lookForBonus = this.getBonuses.filter(
          (el) => makeDate.getDate() === new Date(el.dayFormmated).getDate()
        );
        if (lookForBonus.length) {
          for (let b = 0; b < lookForBonus.length; b++) {
            if (lookForBonus[b].shift === "Dzień") {
              bonuses.day.value = lookForBonus[b].value;
            } else {
              bonuses.night.value = lookForBonus[b].value;
            }
          }
        }
        let isWeekend = makeDate.getUTCDay();
        if (day === 1 && isWeekend !== 0) {
          let days = isWeekend;
          this.firstDay.freeDay = days;
          this.firstDay.isMonday = false;
        }
        let dayNames = [
          "Poniedziałek",
          "Wtorek",
          "Środa",
          "Czwartek",
          "Piątek",
          "Sobota",
          "Niedziela",
        ];
        dayObject.push({
          day: makeDate.getDate(),
          month: makeDate.getMonth() + 1,
          year: makeDate.getFullYear(),
          isWeekend: isWeekend === 5 || isWeekend === 6 ? true : false,
          weekDay: dayNames[isWeekend],
          currentDay: capacity[i].dayShifts,
          currentNight: capacity[i].nightShift,
          currentToChoose: capacity[i].toChoose,
          limitDay: this.carLimit,
          limitNight: this.carLimit,
          bonuses: bonuses,
        });
      }
      return !this.isLoading && dayObject;
    },
    returnWord() {
      if (this.shiftInfo.shiftMissing === 1) {
        return "zmianę";
      }
      if (this.shiftInfo.shiftMissing === 5) {
        return "zmian";
      }
      return "zmiany";
    },
    isFirstDay() {
      if (
        this.showChoosen.length === 1 &&
        this.showChoosen[0].day === this.generateNextMonth[0].day &&
        this.showChoosen[0].shiftOption === "Dzień"
      ) {
        return true;
      } else {
        return false;
      }
    },
    getCapacity() {
      const capacity = this.$store.getters["daysCapacity"];
      return !this.isLoading && capacity;
    },
    returnNextWeek() {
      const week = this.$dayjs().add(1, "week");
      const year = 2025;
      return { week: week.week(), year: year, month: week.month() + 1 };
    },
    showChoosen() {
      let onlyChoosen = this.monthShedule.filter(
        (item) => item.shiftOption !== null && item.shiftOption !== "Wolne"
      );
      return onlyChoosen;
    },
    returnWeek() {
      const currentWeek = this.$store.getters["returnWeek"];
      return !this.isLoading && currentWeek;
    },
    getSettings() {
      return this.$store.getters["getSettings"];
    },
    shiftCounter() {
      let dayShifts = this.showChoosen.filter(
        (item) => item.shiftOption === "Dzień"
      );
      let nightShifts = this.showChoosen.filter(
        (item) => item.shiftOption === "Noc"
      );
      let dyspozycja = this.showChoosen.filter(
        (item) => item.shiftOption === "Do dyspozycji"
      );
      let rezerwaDzien = this.showChoosen.filter(
        (item) => item.shiftOption === "Dzień rezerwa"
      );
      let rezerwaNoc = this.showChoosen.filter(
        (item) => item.shiftOption === "Noc rezerwa"
      );
      return {
        day: dayShifts.length,
        night: nightShifts.length,
        idk: dyspozycja.length,
        total:
          dayShifts.length +
          nightShifts.length +
          dyspozycja.length +
          rezerwaNoc.length +
          rezerwaDzien.length,
      };
    },
    shiftInfo() {
      if (this.shiftCounter.total >= 1 && this.shiftCounter.total < 6) {
        return { bonus: 20, shiftMissing: 6 - this.shiftCounter.total };
      }
      if (this.shiftCounter.total === 6) {
        return { bonus: 50, shiftMissing: 7 - this.shiftCounter.total };
      }
    },
    validate() {
      let fullName;
      let email;
      let phone;
      let filter =
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (this.form.fullName && this.form.fullName.trim().indexOf(" ") !== -1) {
        fullName = true;
      } else if (
        this.form.fullName &&
        this.form.fullName.trim().indexOf(" ") === -1
      ) {
        fullName = false;
      }
      if (this.form.email && filter.test(this.form.email)) {
        email = true;
      } else if (this.form.email) {
        email = false;
      }
      if (this.form.phone && this.form.phone.toString().length < 9) {
        phone = false;
      } else if (this.form.phone) {
        phone = true;
      }
      return { fullName: fullName, email: email, phone: phone };
    },
    checkNightShift() {
      //get option if is turned on
      // check shifts
      let failure = { isError: false, errorDays: [] };
      let month = this.monthShedule;
      for (let i = 1; i < month.length; i++) {
        if (
          month[i].shiftOption === "Dzień" ||
          month[i].shiftOption === "Dzień rezerwa"
        ) {
          if (
            month[i - 1].shiftOption === "Noc" ||
            month[i - 1].shiftOption === "Noc rezerwa"
          ) {
            failure.isError = true;
            failure.errorDays.push(month[i - 1]);
            let index = month[i - 1].day;
          }
        }
      }
      return failure;
    },
    checkReserve(){
      if(this.showChoosen){
        const reserveLength = this.showChoosen.filter(el => el.shiftOption.includes("rezerwa"));
        if(reserveLength.length) {
          return true
        } else {
          return false
        }
      }

    },
    getShiftLimits() {
      return !this.isLoading && this.$store.getters["getLimits"];
    },
    today() {
      // Create a new Date object
      const currentDate = new Date();

      // Get the year, month, and day from the Date object
      const year = currentDate.getFullYear();
      let month = currentDate.getMonth() + 1; // Months are zero-indexed, so we add 1
      let day = currentDate.getDate();

      // Convert single-digit month and day values to two digits
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }

      // Create the formatted date string in the format "YYYY-MM-DD"
      return year + "-" + month + "-" + day;
    },
    getBonuses() {
      let bonusArray = [];
      this.$store.getters["getListOfBonus"].forEach((el) => {
        const date = el.date;

        const parts = date.split("-");

        // Remove leading zero from month and day
        const year = parts[0];
        const month = parseInt(parts[1], 10).toString();
        const day = parseInt(parts[2], 10).toString();

        // Create the new formatted date string
        const dayFormmated = year + "-" + month + "-" + day;
        const value = el.value;
        const shift = el.shift;
        bonusArray.push({ dayFormmated, value, shift });
      });
      return !this.isLoading && bonusArray;
    },
  },
};
</script>

<style>
body {
  background-color: #eeeeee;
}

.temporary {
  text-align: center;
}
.temporary button {
  margin: 0 8px;
  background-color: #d44017;
  color: #fff;
  padding: 8px 32px;
  border: none;
  border-radius: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.logo {
  margin-left: auto;
}
.logo-box {
  width: 480px;
  border-radius: 10px;
  padding: 40px;
  margin: 20px auto;
  background: #ffd933;
  border-radius: 16px;
}
.more-info-box {
  width: 480px;
  border-radius: 10px;
  padding: 40px;
  background: #ededed;
  border-radius: 16px;
}
.cal {
  height: 100%;
  background: url("../assets/otam-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
    padding-bottom: 80px;

}
.cal-container {
  height: 82%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.skeleton-mobile {
  display: none;
}
.calendar-title {
  text-align: center;
  margin: 2em 0;
  text-transform: uppercase;
  letter-spacing: 5px;
}

.calendar-container {
  margin-top: 3vw;
  display: flex;
  justify-content: center;
}
.seven-cols {
  gap: 15px 0px;
  max-width: 1300px;
}
.info-row {
  display: flex;
  justify-content: space-around;
}
.sidebar-content,
.sidebar-summary {
  margin: 20px 0px;
  text-align: center;
}
.sidebar-header {
  margin-top: 20px;
  color: black;
  text-align: center;
  font-weight: 600;
  font-size: 1.4em;
}
.bottom-box {
  position: fixed;
  bottom: 40px;
  right: 40px;
  display: flex;
  gap: 20px;
}
.bottom-box .info {
  text-align: center;
  background-color: #a9a9a9;
  color: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 20px;
}
.bottom-box .submit {
  text-align: center;
  background-color: #808080;
  color: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 20px;
  cursor: pointer;
}
.bottom-box .submit:hover {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
.close {
  cursor: pointer;
  margin-right: 10px;
}
.bonus {
  font-size: 1.1em;
  margin-top: 24px;
  padding-left: 16px;
}
.summary-single {
  color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 10px;
}
.day-summary {
  background-color: #ffa400;
}
.night-summary {
  background-color: #614002;
}
.summary-all {
  background-color: #153a67;
}
.idk-summary {
  background-color: #92a2f3;
}
@media (min-width: 768px) {
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1 {
    width: 100%;
    *width: 100%;
    gap: 20px;
  }
}
@media (max-width: 700px) {
  .more-info-box {
    margin: 0 8px;
  }
  .box-columns {
    margin-top: 16px;
  }
  .bottom-box {
    right: 10px;
  }
  .submit {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .skeleton-mobile {
    display: block;
    margin-top: 20px;
  }
  .b-skeleton-img {
    margin: 0 auto;
  }
  .calendar-container {
    padding-bottom: 20px;
  }
  .skeleton-desktop {
    display: none;
  }
  .logo-box {
    display: block;
    max-width: 95%;
  }
  .logo-box img {
    width: 100%;
    margin:0 8px;
  }
}
.mail {
  text-decoration: none;
  color: inherit;
  font-weight: 500;
}
.box-columns {
  display: flex;
  gap: 16px;
  justify-content: center;
  flex-wrap: wrap;
}
@media (min-width: 992px) {
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1 {
    width: 14.285714285714285714285714285714%;
    *width: 14.285714285714285714285714285714%;
    max-width: 14.285714285714285714285714285714% !important;
    flex: none !important;
  }
}

@media (min-width: 1200px) {
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1 {
    width: 14.285714285714285714285714285714%;
    *width: 14.285714285714285714285714285714%;
    max-width: 14.285714285714285714285714285714% !important;
    flex: none !important;
  }
}

/* animation */
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
