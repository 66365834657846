<template>
<b-container>
<b-row class="week" v-if="!isLoading && getAllWeeks">
    <b-col cols="4" v-for="item in getAllWeeks" :key="item.id">
        <div class="box">
            <div>Tydzień: {{item.week}}</div>
            <div>{{returnWeek(item.week, item.year)}}</div>
            <b-button @click="formatData(item)">Generuj do csv</b-button>
        </div>
    </b-col>
</b-row>
</b-container>
</template>



<script>
import csvExport from '../print_templates/csvExport.js'
export default {
    data(){
        return {
            isLoading: false
        }
    },
    created(){
        this.fetchGeneratedWeeks()
    },
    methods: {
        async fetchGeneratedWeeks(){
            this.isLoading = true;
            await this.$store.dispatch('fetchGeneratedWeeks', {year: 2025})
            this.isLoading = false;
        },
        returnWeek(week,year){
            const formatWeek = this.$dayjs().week(week ).year(year)
            return `${formatWeek.day(1).$D}.${formatWeek.day(1).$M + 1}.${formatWeek.day(1).$y} - ${formatWeek.day(7).$D}.${formatWeek.day(7).$M + 1}.${formatWeek.day(7).$y}`
        },
        formatData(obj){
            const formattedArray = {day: [], nights: []}
            const days = obj.days
            const nights = obj.nights
            for(let a = 0; a < days.length; a++){
                let dayArray = []
                let day = days[a].day
                let month = days[a].month
                for(let b = 0 ; b < days[a].cars.length; b++){
                    let reg = days[a].cars[b].reg
                    let isDriver = days[a].cars[b].drivers.length ? days[a].cars[b].drivers[0].fullName : 'Wolne'
                    let driver = isDriver
                    dayArray.push({reg, driver})
                }
                formattedArray.day.push({day, month , dayArray})
            }
            for(let a = 0; a < nights.length; a++){
                let dayArray = []
                let day = nights[a].day
                let month = nights[a].month
                for(let b = 0 ; b < nights[a].cars.length; b++){
                    let reg = nights[a].cars[b].reg
                    let isDriver = nights[a].cars[b].drivers.length ? nights[a].cars[b].drivers[0].fullName : 'Wolne'
                    let driver = isDriver
                    dayArray.push({reg, driver})
                }
                formattedArray.nights.push({day, month , dayArray})
            }
            csvExport(formattedArray, 'export')   

        }
    },
    computed: {
        getAllWeeks(){
            return !this.isLoading && this.$store.state.allGenerated.sort((a,b) => b.year - a.year)
        }
    }
}
</script>

<style scoped>
.week{
    gap: 15px 0;
}
.box{
    text-align: center;
    background-color: #fff;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
</style>